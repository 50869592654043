import * as React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SiteContainer from "./site-container";
import {graphql, Link} from "gatsby";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

const Career = ({data}) => {
    const job = data.lever

    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - {job.text}</title>
                <link rel="icon"
                      href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>"/>
            </Helmet>
            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <a href="/careers" className="text-gray-700">
                                        Careers
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {job.text}
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>
            <section className="py-8 py-md-11 bg-light">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} className="col-md">
                            <h1 className="display-4 mb-2">
                                {job.text}
                            </h1>
                            <p className="fs-lg text-gray-700 mb-5 mb-md-0">
                                {job.categories.location} · {job.categories.commitment}
                            </p>
                        </Col>
                        <div className="col-auto">

                            <a href={job.applyUrl} className="btn btn-primary">
                                Apply
                            </a>

                        </div>
                    </Row>
                    <div className="row">
                        <div className="col-12">
                            <hr className="my-6 my-md-8 border-gray-300" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <span dangerouslySetInnerHTML={{__html: job.description}} />
                            <br />
                            <span dangerouslySetInnerHTML={{__html: job.additional}} />
                        </div>
                        <div className="col-12 col-md-4 mt-5 mt-md-0">


                            <div className="card shadow-light-lg">
                                <div className="card-body">

                                    <h4>
                                        Don't see the job for you?
                                    </h4>

                                    <p className="fs-sm text-gray-800">
                                        Looking for something with Firstparty, but don't see your dream job?
                                    </p>

                                    <a href="#!" className="fw-bold fs-sm text-decoration-none">
                                        Let us know
                                    </a>

                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </section>

        </SiteContainer>
    )
}

export const query = graphql`
  query($id: String!) {
    lever(id: {eq: $id}) {
        description
        hostedUrl
        id
        lever_id
        text
        additional
        applyUrl
        lists {
          content
        }
        categories {
          commitment
          level
          location
          team
        }
      }
    }
`

export default Career
